import { cloneDeep } from 'lodash'
import {
  isPaymentMethodApplePay,
  isPaymentMethodCC,
  isPaymentMethodPayPal,
} from '~/../common/utils/payment'

const newCheckoutState = () => {
  return {
    isSignUp: true,
    registration: {
      sectionId: 'registration',
      registered: false,
      errors: [],
    },
    shipping: {
      sectionId: 'shipping',
      userAddresses: [],
      methods: {
        address: 'address',
        addressNew: 'addressNew',
        fedex: 'fedex',
        addressGift: 'addressGift',
        addressGiftNew: 'addressGiftNew',
      },
      selectedMethod: null,
      selectedAddress: null,
      selectedPickupAddress: null,
      errors: [],
    },
    deliveryDetails: {
      sectionId: 'delivery-details',
      selectedCarrier: null,
      deliveryMethods: [],
      deliveryDates: null,
      shipDate: null,
      selectedDeliveryDate: null,
      selectedShippingMethod: null,
      previousSelectedHotWeatherOption: null,
      selectedHotWeatherOption: null,
      selectedColdPackOption: 'false',
      isPreArrival: false,
      showPreArrivalMessage: false,
      errors: [],
    },
    payment: {
      sectionId: 'payment',
      selectedPaymentOption: 'paymentOptionCreditCard',
      selectedPaymentMethod: null,
      tokenType: 0,
      paymentMethods: [],
      oneTimePaymentMethods: [],
      appliedGiftCard: false,
      newPaymentSaving: false,
      errors: [],
    },
    giftNote: {
      note: '',
      emailAddress: '',
      saved: true,
    },
    giftNoteStashed: null,
    placeOrder: {
      showEmailSubscriptionCheckbox: false,
      emailSubscriptionChecked: true,
      passageOfTitleStatement: '',
      errors: [],
    },
    postPurchaseOrder: null,
    pptoEligibleOrders: [],
  }
}

const hydrateCheckoutState = (checkoutBasket, checkoutState) => {
  try {
    hydratePPTOState(checkoutBasket, checkoutState)
    hydrateShippingState(checkoutBasket, checkoutState)
    hydrateDeliveryDetailsState(checkoutBasket, checkoutState)
    hydratePaymentState(checkoutBasket, checkoutState)
    hydrateGiftNote(checkoutBasket, checkoutState)

    checkoutState.placeOrder.showEmailSubscriptionCheckbox =
      checkoutBasket.show_email_subscription_checkbox
    checkoutState.placeOrder.emailSubscriptionChecked =
      checkoutBasket.email_subscription_checked

    checkoutState.placeOrder.passageOfTitleStatement =
      checkoutBasket.passage_of_title_statement
  } catch (e) {
    console.error(e)
    throw e
  }
  return checkoutState
}

const getShippingCarrierFromCode = (shippingCode) => {
  return shippingCode.includes('ups') ? 'UPS' : 'FedEx'
}

const setCarrierAndMethodFromCode = (
  checkoutState,
  shippingCode,
  isAsYouGo,
  postPurchaseOrder
) => {
  checkoutState.deliveryDetails.selectedCarrier = getShippingCarrierFromCode(
    shippingCode
  )
  let newShipCode = shippingCode
  if (isAsYouGo) {
    newShipCode = 'as_you_go_' + newShipCode
  }
  if (postPurchaseOrder) {
    newShipCode = `ppto-${postPurchaseOrder}`
  }
  checkoutState.deliveryDetails.selectedShippingMethod = newShipCode
}

const hydratePPTOState = (checkoutBasket, checkoutState) => {
  checkoutState.pptoEligibleOrders = checkoutBasket.ppto_eligible_orders || []
  checkoutState.postPurchaseOrder = checkoutBasket.post_purchase_order
}

const hydrateShippingState = (checkoutBasket, checkoutState) => {
  hydrateCurrentShippingAddress(checkoutBasket, checkoutState)
  if (checkoutBasket.user_addresses !== undefined) {
    checkoutState.shipping.userAddresses = checkoutBasket.user_addresses
  }
}

const hydrateCurrentShippingAddress = (checkoutBasket, checkoutState) => {
  const shipping = checkoutState.shipping
  const userAddresses = checkoutBasket.user_addresses || []
  if (
    checkoutBasket.selected_pickup_address ||
    checkoutBasket.selected_shipping_address
  ) {
    const basketAddress = checkoutBasket.shipping_address
    if (basketAddress.id) {
      if (
        !shipping.selectedAddress ||
        shipping.selectedAddress.id !== basketAddress.id
      ) {
        const idx = userAddresses.findIndex((x) => x.id === basketAddress.id)
        shipping.selectedAddress = userAddresses[idx]
        shipping.selectedMethod = shipping.methods.address
      }
    } else {
      // If no id, it's a pickup address
      shipping.selectedPickupAddress = basketAddress
      shipping.selectedMethod = shipping.methods.fedex
    }
  } else if (userAddresses.length) {
    const defaultIndex = userAddresses.findIndex(
      (x) => x.is_default_for_shipping === true
    )
    shipping.selectedAddress =
      userAddresses[defaultIndex !== -1 ? defaultIndex : 0]
    shipping.selectedMethod = shipping.methods.address
  } else {
    shipping.selectedAddress = null
    shipping.selectedMethod = shipping.methods.addressNew
  }
  // if it's a gift, make sure we display the correct tab
  if (checkoutBasket.gift_note || checkoutBasket.gift_email_address) {
    if (shipping.selectedMethod === shipping.methods.address) {
      shipping.selectedMethod = shipping.methods.addressGift
    } else if (shipping.selectedMethod === shipping.methods.addressNew) {
      shipping.selectedMethod = shipping.methods.addressGiftNew
    }
  }
}

const hydrateDeliveryDetailsState = (checkoutBasket, checkoutState) => {
  checkoutState.deliveryDetails.selectedDeliveryDate =
    checkoutBasket.delivery_date
  checkoutState.deliveryDetails.shipDate = checkoutBasket.ship_date
  checkoutState.deliveryDetails.selectedHotWeatherOption = checkoutBasket.hot_weather_option
    ? checkoutBasket.hot_weather_option
    : null
  checkoutState.deliveryDetails.selectedColdPackOption =
    checkoutBasket.use_ice_pack || 'false'
  checkoutState.deliveryDetails.showPreArrivalMessage =
    checkoutBasket.show_pre_arrival_message
  checkoutState.deliveryDetails.holdUntilFall = checkoutBasket.hold_until_fall
  checkoutState.deliveryDetails.cartFirstShipDate =
    checkoutBasket.first_ship_date

  setCarrierAndMethodFromCode(
    checkoutState,
    checkoutBasket.selected_shipping_code,
    checkoutBasket.is_as_you_go,
    checkoutBasket.post_prurchase_order
  )
}

const hydratePaymentState = (checkoutBasket, checkoutState) => {
  hydratePaymentMethods(checkoutState, checkoutBasket.payment_methods)
  // Only select payment method from basket if there are no one-time payment methods AND
  // there are payment methods on file
  if (
    checkoutState.payment.paymentMethods.length > 0 &&
    checkoutState.payment.oneTimePaymentMethods.length === 0
  ) {
    checkoutState.payment.tokenType = checkoutBasket.payment_token_type
    const method = checkoutState.payment.paymentMethods.find(
      (x) => x.card_id === checkoutBasket.payment_token
    )
    if (method) {
      checkoutState.payment.selectedPaymentMethod = method.card_id
      if (method.is_paypal) {
        checkoutState.payment.selectedPaymentOption = 'paymentOptionPayPal'
      } else if (method.is_applepay) {
        checkoutState.payment.selectedPaymentOption = 'paymentOptionApplePay'
      } else {
        checkoutState.payment.selectedPaymentOption = 'paymentOptionCreditCard'
      }
    }
  }
  if (checkoutBasket.offer_discounts) {
    checkoutState.payment.appliedGiftCard =
      checkoutBasket.voucher_discounts &&
      checkoutBasket.voucher_discounts.length > 0
  }
}
const hydratePaymentMethods = (checkoutState, paymentMethods) => {
  checkoutState.payment.paymentMethods = []
  if (!paymentMethods) return
  paymentMethods.forEach((paymentMethod) => {
    paymentMethod.is_paypal = isPaymentMethodPayPal(paymentMethod)
    paymentMethod.is_applepay = isPaymentMethodApplePay(paymentMethod)
    paymentMethod.is_cc = isPaymentMethodCC(paymentMethod)
    paymentMethod.one_time = false
    checkoutState.payment.paymentMethods.push(paymentMethod)
  })
}

export const hydrateGiftNote = (checkoutBasket, checkoutState) => {
  if (checkoutState.giftNote.saved) {
    checkoutState.giftNote.note = checkoutBasket.gift_note
    checkoutState.giftNote.emailAddress =
      checkoutBasket.gift_email_address || ''
  }
}

const state = () => ({
  checkoutState: newCheckoutState(),
})
const mutations = {
  HYDRATE_CHECKOUT_STATE(state, basket) {
    state.checkoutState = hydrateCheckoutState(basket, state.checkoutState)
  },
  SET_CHECKOUT_STATE(state, checkoutState) {
    state.checkoutState = cloneDeep(checkoutState)
  },
  SET_CHECKOUT_DELIVERY_METHODS(state, deliveryMethods) {
    state.checkoutState.deliveryDetails.deliveryMethods = deliveryMethods
  },
}

const actions = {
  hydrateCheckoutState({ commit }, basketState) {
    commit('HYDRATE_CHECKOUT_STATE', basketState)
  },
  setCheckoutState({ commit }, checkoutState) {
    commit('SET_CHECKOUT_STATE', checkoutState)
  },
  setCheckoutDeliveryMethods({ commit }, deliveryMethods) {
    commit('SET_CHECKOUT_DELIVERY_METHODS', deliveryMethods)
  },
}

const getters = {
  checkoutState(state) {
    return state.checkoutState
  },
  checkoutStateDeliveryMethods(state) {
    return state.checkoutState.deliveryDetails.deliveryMethods
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
