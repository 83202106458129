const state = () => ({
  ppto_orders: [],
  site_offers: [],
  user_offers: [],
  promo_codes: [],
  show_ppto: false,
  ppto_link: '',
  open: true,
  marketing_messages: [],
  call_idx: 0,
})

const CLOSED_COOKIE = 'closed_ppto_banner'

const mutations = {
  GET_PROMO_BANNER(state, params) {
    Object.assign(state, params)
  },
  CLOSE_PROMO_BANNER(state, params) {
    state.open = false

    if (showPPTO(state)) {
      window.$nuxt.$cookies.set(CLOSED_COOKIE, true, {
        path: '/',
        maxAge: 60 * 60 * 24, // One hour
      })
    }
  },
}

const showPPTO = (state) => {
  return (
    state.show_ppto &&
    state.ppto_orders.length > 0 &&
    !window.$nuxt.$route.path.includes('checkout') &&
    !window.$nuxt.$cookies.get(CLOSED_COOKIE)
  )
}

const actions = {
  getPromoBanner({ commit }) {
    this.$axios.get('/api/customer/promo_banner/').then((resp) => {
      const data = { ...resp.data, ...{ call_idx: state.call_idx + 1 || 1 } }
      commit('GET_PROMO_BANNER', data)
    })
  },
  closePromoBanner({ commit }) {
    commit('CLOSE_PROMO_BANNER')
  },
}

const getters = {
  pptoOrders(state) {
    return state.ppto_orders
  },
  firstPptoOrder(state) {
    if (state.ppto_orders && state.ppto_orders.length > 0) {
      return state.ppto_orders[0]
    }
    return null
  },
  siteOffers(state) {
    return state.site_offers
  },
  userOffers(state) {
    return state.user_offers
  },
  promoCodes(state) {
    return state.promo_codes
  },
  showPPTO(state) {
    return showPPTO(state)
  },
  pptoLink(state) {
    return state.ppto_link
  },
  showPromoBanner(state) {
    return (
      state.open &&
      (state.site_offers.length > 0 ||
        state.user_offers.length > 0 ||
        state.show_ppto)
    )
  },
  promoMarketingMessages(state) {
    return state.marketing_messages
  },
  promoCallIndex(state) {
    return state.call_idx
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
